import { graphql, useStaticQuery } from "gatsby"
import { useLocation } from "@reach/router"
import React from "react"
import { Helmet } from "react-helmet"

export default function Seo({
  title,
  description,
  image,
  remoteImage,
  article,
  keywords,
}) {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    remoteImage: remoteImage,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      <meta name="description" content={seo.description} />
      {keywords &&
        keywords.map(keyword => (
          <meta name="keyword" content={keyword["keyword"]} />
        ))}
      {seo.remoteImage ? (
        <meta name="image" content={seo.remoteImage} />
      ) : (
        <meta name="image" content={seo.image} />
      )}
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.remoteImage ? (
        <meta property="og:image" content={seo.remoteImage} />
      ) : (
        <meta property="og:image" content={seo.image} />
      )}
      <meta name="twitter:card" content="summary_large_image" />
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.remoteImage ? (
        <meta name="twitter:image" content={seo.remoteImage} />
      ) : (
        <meta name="twitter:image" content={seo.image} />
      )}
    </Helmet>
  )
}

Seo.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}

const query = graphql`
  query {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        defaultImage: image
      }
    }
  }
`
